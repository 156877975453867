import request, { MarkcoinRequest } from '@/plugins/request'
import {
  CurrencyReq,
  ChargeReq,
  ChargeResp,
  PayChargeReq,
  PayChargeResp,
  ChargeInfoReq,
  ChargeInfoResp,
  WithdrawInfoReq,
  WithdrawInfoResp,
  TradeChannelReq,
  TradeChannelResp,
  CurrencyResp,
  PayWithdrawReq,
  PayWithdrawResp,
  PayPreWithdrawReq,
  PayPreWithdrawResp,
  FiatPriceResp,
} from '@/typings/api/assets/fiat'

/**
 * 获取法币列表
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22051
 */
export const getCurrencyList: MarkcoinRequest<CurrencyReq, CurrencyResp> = params => {
  return request({
    path: `/v1/c2c/pay/fiat/currency`,
    method: 'GET',
    params,
  })
}

/**
 * 充值页面内容
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22052
 */
export const getChargeList: MarkcoinRequest<ChargeReq, ChargeResp> = params => {
  return request({
    path: `/v1/c2c/pay/charge/info`,
    method: 'GET',
    params,
  })
}

/**
 * 充值
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22052
 */
export const getPayCharge: MarkcoinRequest<PayChargeReq, PayChargeResp> = data => {
  return request({
    path: `/v1/c2c/pay/charge`,
    method: 'POST',
    data,
  })
}

/**
 * 充值页面内容
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22052
 */

export const getChargeInfo: MarkcoinRequest<ChargeInfoReq, ChargeInfoResp> = params => {
  return request({
    path: `/v1/c2c/pay/footer`,
    method: 'GET',
    params,
  })
}

/**
 * 提现页面信息
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22053
 */

export const getWithdrawInfo: MarkcoinRequest<WithdrawInfoReq, WithdrawInfoResp> = params => {
  return request({
    path: `/v1/c2c/pay/withdraw/info`,
    method: 'GET',
    params,
  })
}

/**
 * 提现
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22056
 */

export const getPayWithdraw: MarkcoinRequest<PayWithdrawReq, PayWithdrawResp> = data => {
  return request({
    path: `/v1/c2c/pay/withdraw`,
    method: 'POST',
    data,
  })
}

/**
 * 法币充提 -获取法币对 USDT 的汇率
 */
export const getFiatPrice: MarkcoinRequest<{ fiatCode: string }, FiatPriceResp> = params => {
  return request({
    path: '/v1/c2c/pay/rate',
    method: 'GET',
    params,
  })
}

/**
 * 充值/提现入口设置
 */
export const getTradeChannel: MarkcoinRequest<TradeChannelReq, TradeChannelResp> = params => {
  return request({
    path: '/v1/c2c/commonSetting/trade/channel',
    method: 'GET',
    params,
  })
}

/**
 * 提现预检查
 */
export const getPayPreWithdraw: MarkcoinRequest<PayPreWithdrawReq, PayPreWithdrawResp> = params => {
  return request({
    path: '/v1/c2c/pay/pre-withdraw',
    method: 'GET',
    params,
  })
}

/**
 * 获取默认货币
 */
export const getDefaultCurrency: MarkcoinRequest<{ fiatCode: string }> = params => {
  return request({
    path: '/v1/c2c/pay/default/fiat/currency',
    method: 'GET',
    params,
  })
}
