/**
 * 法币充提汇率换算
 * @param amount 数量
 * @param rate 法币汇率
 * @param precision 法币精度
 * @param isUSDT 是 usdt 换成其他币种 还是其他币种换算成 usdt
 * @param activityAmount 空投金额
 * @param isThousandths 是否是千分位
 * @returns 汇率换算后的金额
 */

import { decimalUtils } from '@nbit/utils'
import { formatCurrency, formatNumberDecimal } from '../decimal'

export const rateFilterFaitAmount = (
  amount,
  rate: number,
  isUSDT: boolean,
  precision: number,
  isThousandths,
  activityAmount?
) => {
  const SafeCalcUtil = decimalUtils.SafeCalcUtil
  if (activityAmount) {
    const activityAmountVal = formatNumberDecimal(
      Math.max(0, Number(SafeCalcUtil.sub(`${SafeCalcUtil.mul(rate, amount)}`, activityAmount))),
      precision
    )
    if (isThousandths) {
      return formatCurrency(activityAmountVal)
    }
    return activityAmountVal
  } else {
    if (isUSDT) {
      const multiplyVal = formatNumberDecimal(SafeCalcUtil.mul(rate, amount), precision)
      if (isThousandths) {
        return formatCurrency(multiplyVal)
      }
      return multiplyVal
    } else {
      const divideVal = formatNumberDecimal(SafeCalcUtil.mul(amount, 1 / (rate ?? 1)), precision)
      if (isThousandths) {
        return formatCurrency(divideVal)
      }
      return divideVal
    }
  }
}
