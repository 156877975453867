/**
 * 法币冲提
 */
import produce from 'immer'
import { create } from 'zustand'

import { createTrackedSelector } from 'react-tracked'
import { subscribeWithSelector } from 'zustand/middleware'
import { faitEnum } from '@/typings/store/common'
import { getCodeDetailListBatch } from '@/apis/common'
import { PaymentDicCodeEnum } from '@/constants/assets/fiat'
import { CommonDictionaryTypeEnum } from '@/constants/common'

type IStore = ReturnType<typeof getStore>

function getStore(set, get) {
  return {
    faitEnums: {
      c2cPaymentColorEnum: {
        codeKey: CommonDictionaryTypeEnum.paymentColor,
        enums: [],
      } as faitEnum,
      c2cPaymentTypeEnum: {
        codeKey: CommonDictionaryTypeEnum.paymentType,
        enums: [],
      } as faitEnum,
      paymentDicCodeEnum: {
        codeKey: PaymentDicCodeEnum.paymentDicCode,
        enums: [],
      } as faitEnum,
    },
    async faitFuturesEnums() {
      const state: IStore = get()
      const data = await getCodeDetailListBatch(Object.values(state.faitEnums).map(item => item.codeKey))
      set(
        produce((draft: IStore) => {
          const items = Object.values(draft.faitEnums)
          items.forEach((item, index) => {
            item.enums = data[index].map(enumValue => {
              return {
                codeKey: enumValue.codeKey,
                codeVal:
                  parseInt(enumValue.codeVal, 10).toString() === enumValue.codeVal
                    ? parseInt(enumValue.codeVal, 10)
                    : enumValue.codeVal,
              }
            })
          })
        })
      )
    },
  }
}

const baseAssetsFiatStore = create(subscribeWithSelector(getStore))

const useAssetsFiatStore = createTrackedSelector(baseAssetsFiatStore)

export { useAssetsFiatStore, baseAssetsFiatStore }
